export default function (options) {
   return function (deck) {
      const stepNodeSelectorTag = typeof options === 'string' ? options : 'step';
      const stepNodeSelector = `*[${stepNodeSelectorTag}]`;

      const bespokeStepClass = 'bespoke-step';
      const activeClass = bespokeStepClass + '-active';
      const inactiveClass = bespokeStepClass + '-inactive';
      const currentClass = bespokeStepClass + '-current';

      let activeSlideIndex = 0;
      let activeStepIndex = -1;

      const stepNodes = deck.slides.map(slide => {
         return [...slide.querySelectorAll(stepNodeSelector)]
            .filter(node => node.tagName !== 'SCRIPT')
            .map(node => ({ id: parseInt(node.attributes[stepNodeSelectorTag].value), node }))
            .sort((nodeA, nodeB) => nodeA.id - nodeB.id)
            .reduce((acc, curr) => {
               const currentInsertIndex = acc.findIndex(nodeWrapper => nodeWrapper.ids.includes(curr.id));
               if (currentInsertIndex > -1) {
                  acc[currentInsertIndex].ids.push(curr.id);
                  acc[currentInsertIndex].nodes.push(curr.node);
               } else {
                  acc.push({ ids: [curr.id], nodes: [curr.node] });
               }
               return acc;
            }, []);
      });

      stepNodes.forEach(slide => {
         slide.forEach(nodeWrapper => {
            nodeWrapper.nodes.forEach(node => {
               addClass(node, bespokeStepClass);
            })
         })
      })

      const hasSlideChanged = (slideIndex) => activeSlideIndex !== slideIndex;

      function setActiveSlideIndex(slideIndex) {
         activeSlideIndex = slideIndex;
      }

      function setActiveStepIndex(stepIndex) {
         if (typeof stepIndex === 'number') {
            activeStepIndex = stepIndex;
         } else if (typeof stepIndex === 'function') {
            activeStepIndex = stepIndex(activeStepIndex);
         } else {
            throw new TypeError("stepIndex must be a number or a function (oldIndex) => newIndex");
         }
         activateStep(activeSlideIndex, activeStepIndex);
      }

      function hasSlideNextStep() {
         return isStepIndexInRange(activeSlideIndex, activeStepIndex + 1);
      }

      function hasSlidePreviousStep() {
         return isStepIndexInRange(activeSlideIndex, activeStepIndex - 1);
      }

      function isStepIndexInRange(slideIndex, stepIndex) {
         const stepCount = (stepNodes[slideIndex] || []).length
         return stepCount > 0 && stepIndex >= -1 && stepIndex < stepCount
      }

      function removeClass(node, cl) {
         if (node.classList.contains(cl)) {
            node.classList.remove(cl);
         }
      }

      function addClass(node, cl) {
         if (!node.classList.contains(cl)) {
            node.classList.add(cl);
         }
      }

      function activateStep(slideIndex, stepIndex) {
         stepNodes[slideIndex].forEach((nodeWrapper, i) => nodeWrapper.nodes.forEach(node => {
            if (i < stepIndex) {
               removeClass(node, currentClass);
               removeClass(node, inactiveClass);
               addClass(node, activeClass);
            } else if (i > stepIndex) {
               removeClass(node, activeClass);
               removeClass(node, currentClass);
               addClass(node, inactiveClass)
            } else {
               removeClass(node, inactiveClass);
               addClass(node, activeClass);
               addClass(node, currentClass);
            }
         }));
         activeStepIndex = stepIndex;
      }

      function onNext(event) {
         console.log("next: " + event.index);
         if (hasSlideChanged(event.index)) {
            setActiveSlideIndex(event.index);
            const activeSlideStepCount = stepNodes[activeSlideIndex].length;
            if (activeSlideStepCount > 0) {
               setActiveStepIndex(0);
               return false;
            }
            return true;
         }
         if (hasSlideNextStep()) {
            setActiveStepIndex(i => i + 1);
            return false;
         }
         return true;
      }

      function onPrev(event) {
         console.log("prev: " + event.index);
         if (activeSlideIndex < event.index) {
            setActiveSlideIndex(event.index);
            return true;
         }

         if (hasSlideChanged(event.index)) {
            setActiveSlideIndex(event.index);
            const activeSlideStepCount = stepNodes[activeSlideIndex].length;
            if (activeSlideStepCount > 0) {
               setActiveStepIndex(activeSlideStepCount - 2);
               return false;
            }
            return true;
         }
         if (hasSlidePreviousStep()) {
            setActiveStepIndex(i => i - 1);
            return false;
         }
         return true;
      }

      function onSlide(event) {
         setActiveSlideIndex(event.index)
         setActiveStepIndex(-1);
      }

      deck.on('next', onNext);
      deck.on('prev', onPrev);
      deck.on('slide', onSlide);
   }
}