import bespoke from 'bespoke';
import classes from 'bespoke-classes';
import nav from 'bespoke-nav';
import scale from 'bespoke-scale';
import bullets from 'bespoke-bullets';
import hash from 'bespoke-hash';
import prism from 'bespoke-prism';
import multimedia from 'bespoke-multimedia';
import extern from 'bespoke-extern';
import progress from 'bespoke-progress';
import steps from './bespoke-steps';

// Bespoke.js
bespoke.from({ parent: 'article.deck', slides: 'section' }, [
  progress(),
  classes(),
  nav(),
  steps(),
  // scale(),
  // bullets('.build, .build-items > *:not(.build-items)'),
  hash(),
  prism(),
  multimedia(),
  extern(bespoke)
]);
